.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none; /* Remove o traçado (sublinhado) */
  color: inherit; /* Herda a cor do elemento pai ou ajusta para o estilo desejado */
}

a:visited {
  color: inherit; /* Remove a cor padrão de link visitado */
}

a:hover,
a:active {
  text-decoration: none; /* Garante que o traçado não volte ao interagir */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.boxResumeTitle {
  font-weight: 800;
}

.boxResume {
  padding-bottom: 10px;
}

.status {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 10px;
}
.status.online,
.status.online:before {
  background: #72ff7d;
}
.status.offline,
.status.offline:before {
  background: #ff4242;
}
.status.invisible,
.status.invisible:before {
  background: #42e5ff;
}
.status.idle,
.status.idle:before {
  background: #ffda72;
}
.status:before {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in;
}

.shine-button {
  position: relative;
  overflow: hidden;
}

.shine-button:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 220px;
  position: absolute;
  z-index: 1;
  animation: slide 2s infinite;

  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.gold-button {
  color: #000000;
  background-image: linear-gradient(180deg, #f6e27a 20%, #ecd1aa 80%);
  font-weight: bold;
  padding: 7px 13px;
  border-radius: 8px;
  border-width: 0;
  gap: 7px;
  display: inline-flex;
  align-items: center;

  box-shadow: 0 4px 12px 0 #ffcd5759;
  transition: all 0.5s ease;

  justify-content: center;
  text-align: center;
  text-decoration: none;

  position: relative;
  overflow: hidden;
  font-family: "Zain";
  font-weight: 900;
  transform: scale(0.8);

  flex: 1;
}
.gold-button .text {
  line-height: 5px;
  margin-top: 5px;
  font-family: "Zain";
}
.shineBtn {
  position: relative;
  margin: 10px;
  z-index: 3;
}
.shineBtn.online2:after {
  background: #3f67f3;
  border-radius: 50px;
}

.shineBtn.online2,
.shineBtn.online2:before {
  background: #3f67f3;
  border-radius: 50px;
}
.shineBtn.offline2,
.shineBtn.offline2:before,
.shineBtn.offline2:after {
  background: #ff4242;
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Main Ticket Style */
.ticketContainerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
}
.ticketTop {
  animation: bouncingCard 0.6s ease-out infinite alternate;
  background-color: white;
  color: darkslategray;
  border-radius: 12px;
  width: 110% !important;
}
.ticketShadowTop {
  animation: bouncingShadow 0.6s ease-out infinite alternate;
  margin-top: 4px;
  width: 95%;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  filter: blur(12px);
}
.hidden {
  display: none;
  visibility: hidden;
}
/* Ticket Content */
.ticketTitleTopRanking {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 12px 14px 4px;
}
.ticketTitleTop {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0px 16px 4px;
  margin-top: -10px;
}
.ticketDetailTop {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 4px 16px;
}
.ticketDetailCenter {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ticketSubDetailTop {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 12px 16px;
}
.ticketSubDetailTop .codeTop {
  margin-right: 24px;
}

/* Ticket Ripper */
.ticketRipTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.circleLeftTop {
  width: 12px;
  height: 24px;
  background-color: #b7b5e4;
  border-radius: 0 12px 12px 0;
}
.ripLineTop {
  width: 100%;
  border-top: 3px solid #b7b5e4;
  border-top-style: dashed;
}
.circleRightTop {
  width: 12px;
  height: 24px;
  background-color: #b7b5e4;
  border-radius: 12px 0 0 12px;
}

/* Ouro */
.circleLeftTopGold {
  width: 12px;
  height: 24px;
  background-color: #ffd700;
  border-radius: 0 12px 12px 0;
}
.ripLineTopGold {
  width: 100%;
  border-top: 3px solid #ffd700;
  border-top-style: dashed;
}
.circleRightTopGold {
  width: 12px;
  height: 24px;
  background-color: #ffd700;
  border-radius: 12px 0 0 12px;
}

/* Prata */
.circleLeftTopSilver {
  width: 12px;
  height: 24px;
  background-color: #ce8946;
  border-radius: 0 12px 12px 0;
}
.ripLineTopSilver {
  width: 100%;
  border-top: 3px solid #ce8946;
  border-top-style: dashed;
}
.circleRightTopSilver {
  width: 12px;
  height: 24px;
  background-color: #ce8946;
  border-radius: 12px 0 0 12px;
}

/* Ouro */
.circleLeftTopBron {
  width: 12px;
  height: 24px;
  background-color: #c0c0c0;
  border-radius: 0 12px 12px 0;
}
.ripLineTopBron {
  width: 100%;
  border-top: 3px solid #c0c0c0;
  border-top-style: dashed;
}
.circleRightTopBron {
  width: 12px;
  height: 24px;
  background-color: #c0c0c0;
  border-radius: 12px 0 0 12px;
}

.dateTop {
  position: relative;
}

.dateTop img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90px;
}

/* Ticket Bouncing Animation */
@keyframes bouncingCard {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-18px);
  }
}
@keyframes bouncingShadow {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(4px);
  }
}

.box-ranking {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

@media (max-width: 300px) {
}

.menuContent {
  position: fixed;
  z-index: 999999;
  backdrop-filter: blur(5px);
  background-color: #0000005f;
}

.menuBilhete {
  background-color: red;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: #fdf7ecd8;
  border-left: 2px;
}

.shineBtn.invisible2,
.shineBtn.invisible2:before,
.shineBtn.invisible2:after {
  background: #42e5ff;
}
.shineBtn.idle2,
.shineBtn.idle2:before,
.shineBtn.idle2:after {
  background: #ffda72;
}
.shineBtn:before,
.shineBtn:after {
  content: "";
  display: block;
  position: absolute;
  top: -25%;
  left: -13%;
  opacity: 0;
  width: 125%;
  height: 150%;
  z-index: -1;
  animation: pulseBtn 1.5s infinite ease-in;
}
.shineBtn:after {
  z-index: -2 !important;
  animation-delay: 0.3s;
}

@keyframes pulseBtn {
  0% {
    transform: scale(0.7);
    height: 100%;
    width: 125%;
    top: 0%;
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    height: 125%;
    width: 110%;
    left: -5%;
    top: -11.5%;
    opacity: 0;
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.modalEnd .MuiDialog-container {
  align-items: flex-end;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.numberButtonsHidden input[type="number"]::-webkit-inner-spin-button,
.numberButtonsHidden input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.App-link {
  color: #61dafb;
}

.headerRifa {
  position: sticky;
  min-height: 70px;
  top: 0;
  width: 100%;
  z-index: 99999;
  transition: 0.4s all;
  backdrop-filter: blur(5px);
  background-color: #fdf7ecd8;
  border-bottom: 1px solid #cfcbc4;
  justify-content: center;
  display: flex;
  align-items: center;
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

body {
  background-color: #fdf7ecd8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rec-carousel {
  position: relative !important;
}
.rec-slider-container {
  margin: 0 !important;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.MuiTypography-div {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
